<template>
    <div class="container">
        <svg width="255" :height="titleProp.height" class="text">
            <!-- 定义渐变颜色 -->
            <defs>
                <linearGradient id="geekColor" gradientTransform="rotate(90)">
                    <stop offset="0%" stop-color="#91bd3a">
                        <!-- 渐变动画 -->
                        <animate
                                attributeName="stop-color"
                                values="#91bd3a;#12cad6;#91bd3a"
                                dur="10s"
                                begin="3s"
                                repeatCount="indefinite"
                        ></animate>
                    </stop>

                    <stop offset="100%" stop-color="#12cad6">
                        <animate
                                attributeName="stop-color"
                                values="#12cad6;#91bd3a;#12cad6"
                                dur="10s"
                                begin="3s"
                                repeatCount="indefinite"
                        ></animate>
                    </stop>
                </linearGradient>
            </defs>
            <text class="logo_text" :style="{ stroke: titleProp.color }" x="0" y="50">
                {{ titleProp.title }}
            </text>
        </svg>
    </div>
</template>

<script>
    export default {
        data () {
            return {}
        },
        props: {
            titleProp: {
                type: Object,
                default () {
                    return {
                        title: '',
                        height: '80px',
                        color: '#0f9'
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .container {
        box-sizing: border-box;
    }
    .logo_text {
        font-family: 'PingFang SC';
        font-size: 30px;
        font-weight: 600;
        fill: none;
        stroke-width: 2;
        stroke-dasharray: 10 800;
        stroke-dashoffset: 10;
        animation: 5s linear 0s drawing forwards;
    }

    /* 绘画动画 */
    @keyframes drawing {
        to {
            stroke-dasharray: 800;
            stroke-dashoffset: 0;
        }
    }
</style>
