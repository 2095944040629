import Vue from 'vue'
import App from './App.vue'
import './assets/css/reset.css'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faSearch,
    faChevronUp,
    faAudioDescription,
    faHome,
    faDownload,
    faCoins,
    faLandmark,
    faHandshake,
    faGlobe,
    faBullhorn
} from '@fortawesome/free-solid-svg-icons';
import VueSmoothScroll from 'vue2-smooth-scroll'

Vue.use(VueSmoothScroll, {
    duration: 500,        // 以毫秒为单位的动画持续时间
    offset: -80,            // 以像素为单位的滚动元素偏移量，可以是正数或负数
    updateHistory: true,  // 是否将哈希推送到历史记录
    easingFunction: null  // 提供提供自定义缓动函数 `t => ...`
    // 如果没有给出任何内容，它将默认为 `easeInOutCubic`
})
library.add(
    faSearch,
    faChevronUp,
    faAudioDescription,
    faHome,
    faDownload,
    faCoins,
    faLandmark,
    faHandshake,
    faGlobe,
    faBullhorn
);
Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    render: h => h(App),
}).$mount('#app')
