<template>
    <div id="Home">
        <header class="header" :class="[isBurger? 'open' : '',isOverHeight? 'sticky' : '',]">
            <div class="logo">
                <TextEffect :titleProp="titleProp"/>
            </div>
            <nav>
                <a href="#main"  v-smooth-scroll>首页</a>
                <a href="#about-us" v-smooth-scroll>关于公司</a>
                <a href="#application-intro" v-smooth-scroll>应用介绍</a>
                <a href="#concat" v-smooth-scroll>联系我们</a>
<!--                <font-awesome-icon class="fasIcon" :icon="['fas', 'search']"></font-awesome-icon>-->
            </nav>
            <!-- 折叠导航按钮 -->
            <div class="burger" @click="handleBurger">
                <div class="burger-line1"></div>
                <div class="burger-line2"></div>
                <div class="burger-line3"></div>
            </div>
        </header>
        <div class="swiper-container" id="main">
            <h1>世界因我们变得更美好</h1>
            <h4>我们努力做美丽而智能的工程师,打造专业,专注,价值,精致的产品</h4>
            <div class="moreBtn">
                <span><a href="#about-us" v-smooth-scroll>探索更多</a></span>
            </div>
        </div>
        <div class="wrap-content">
            <section id="about-us" class="about-us-section">
                <h2 class="bigTitle">关于公司</h2>
                <p class="intro">公司以“成为最值得信赖的出行服务企业”为愿景，“科技引领 愉悦出行”为使命，致力于成为能够为客户和政府提供“安全、便捷、品质”出行服务的科技创新型企业</p>
                <div class="features">
                    <div class="feature">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'bullhorn']"></font-awesome-icon>
                        <h4 class="feature-title">公司宣言</h4>
                        <p class="feature-text">致力于成为能够为客户和政府提供“安全、便捷、品质”出行服务的科技创新型企业</p>
                    </div>
                    <div class="feature">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'handshake']"></font-awesome-icon>
                        <h4 class="feature-title">合作伙伴</h4>
                        <p class="feature-text">酷外智联，神舟飞箭</p>
                    </div>
                    <div class="feature">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'globe']"></font-awesome-icon>
                        <h4 class="feature-title">未来愿景</h4>
                        <p class="feature-text">成为最值得信赖的出行服务企业</p>
                    </div>
                </div>
            </section>
            <!-- section-bg 区域灰色背景 -->
            <!-- 服务流程 -->
            <section id="application-intro" class="application section-bg">
                <h2 class="bigTitle">应用介绍</h2>
                <p class="intro">
                    酷飞出行是基于电动自动车智能出行的工具，可以能过蓝牙实现对智能电动自行车的绑定后管理，包括开关锁、里程、电量、故障、骑行轨迹等记录
                </p>
                <!-- 同关于我们 -->
                <div class="applications">
                    <div class="application-item">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'home']"></font-awesome-icon>
                        <h2 class="application-title">应用官网</h2>
                        <p class="application-content">
                            <a href="http://www.coolwal.com/admin">http://www.coolwal.com/</a>
                        </p>
                    </div>
                    <div class="application-item">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'download']"></font-awesome-icon>
                        <h2 class="application-title">应用下载链接</h2>
                        <p class="application-content">
                            <a href="https://www.pgyer.com/hEwd"> https://www.pgyer.com/hEwd </a>
                        </p>
                    </div>
                    <div class="application-item">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'coins']"></font-awesome-icon>
                        <h2 class="application-title">应用类目</h2>
                        <p class="application-content">
                            IT科技-软件服务提供商
                        </p>
                    </div>
                    <div class="application-item">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'audio-description']"></font-awesome-icon>
                        <h2 class="application-title">应用说明</h2>
                        <p class="application-content">
                            实现对智能电动自行车的绑定后管理
                        </p>
                    </div>
                    <div class="application-item">
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'landmark']"></font-awesome-icon>
                        <h2 class="application-title">所属公司</h2>
                        <p class="application-content">
                            深圳市酷外智联科技有限公司
                        </p>
                    </div>
                </div>
            </section>
        </div>
        <footer>
            <!-- 底部菜单 -->
            <div class="footer-menus" id="concat">
                <!-- 联系信息 -->
                <div class="contact-us" >
                    <p class="menu-title">联系我们</p>
                    <p>地址：深圳市光明区公明街道上村社区公常公路890号石观工业园第8栋301</p>
                    <p>电话：15015500052</p>
                    <p>电子邮箱：577760416@qq.com</p>
                </div>
                <!-- 备案信息 -->
                <p class="icp-info">
                    <img src="../assets/images/police_icon.d0289dc0.png" alt="">
                    <span><a href="https://beian.miit.gov.cn" target="_blank" style="color: white;text-decoration: underline;">粤ICP备2024241766号-4</a></span>
                </p>
                <!-- 版权信息 -->
                <p class="rights">
                    &copy; 2024 酷外智联科技有限公司官网 版权所有
                </p>
                <!-- 返回顶部按钮 -->
                <div class="scrollToTop" :style="{ 'display': isOverHeight ? 'block':'none'}">
                    <a href="#main" v-smooth-scroll>
                        <font-awesome-icon class="fasIcon" :icon="['fas', 'chevron-up']"></font-awesome-icon>
                    </a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
    import TextEffect from "../components/textEffect.vue";

    export default {
        data() {
            return {
                titleProp: {
                    title: "酷外",
                    height: "80px",
                    color: "#494949",
                },
                isBurger: false,
                isOverHeight: false,
            };
        },
        components: {
            TextEffect,
        },
        methods: {
            handleBurger() {
                this.isBurger = !this.isBurger
            },
            handleScroll() {
                let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                let viewHeight = document.documentElement.clientHeight
                // console.log(scrollTop, viewHeight);
                this.isOverHeight = scrollTop >= viewHeight
            },
            handleClick() {
                this.handleBurger()
            },
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll, true)
        }
    };
</script>

<style lang="less" scoped>
    #Home {
        width: 100%;
        overflow-x: hidden;
    }

    .header {
        box-sizing: border-box;
        position: relative;
        width: 100vw;
        height: 80px;
        display: grid;
        padding: 0 40px;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        z-index: 99;
    }

    .header nav {
        justify-self: flex-end;
        display: flex;
        align-items: center;
    }

    .header nav a {
        text-decoration: none;
        margin: 0 24px;
        color: #666;
    }

    .header nav .fasIcon {
        color: #666;
    }

    /* 折叠菜单 */
    header .burger {
        display: none;
    }

    /* 页面下滑时固定导航，背景设置为白色 */
    header.sticky {
        position: fixed;
        background-color: white;
        box-shadow: 0 0 18px rgba(0, 0, 0, 0.2);
        animation: dropDown 0.5s ease-in-out forwards;
    }

    /* 固定导航文字颜色为黑色 */
    header.sticky nav a,
    header.sticky nav .fasIcon {
        color: #494949;
    }

    /* 固定导航下滑动画 */
    @keyframes dropDown {
        from {
            transform: translateY(-100px);
        }
        to {
            transform: translateY(0);
        }
    }

    .swiper-container {
        position: relative;
        width: 100%;
        height: 100vh;
        background: url("../assets/images/bgImage.jpg") no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 80px;
        top: -80px;
        z-index: 50;

        h1 {
            font-size: 54px;
            font-weight: 600;
        }

        h4 {
            font-size: 24px;
            margin: 48px 0;
        }

        .moreBtn {
            padding: 14px 32px;
            background-color: #ff434f;
            border: 0;
            border-radius: 4px;
            color: #e7e9ec;
            font-size: 18px;
            cursor: pointer;
            outline: none;

            a {
                color: inherit;
            }
        }
    }

    .wrap-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
    }

    section {
        display: grid;
        /* 单元格都居中对齐 */
        justify-items: center;
        max-width: 1180px;
        padding: 0 80px;
        box-sizing: border-box;
    }

    .bigTitle {
        font-size: 34px;
        color: #494949;
        font-weight: 600;

        &:after {
            content: "";
            display: block;
            width: 80%;
            height: 4px;
            transform: translateX(10%);
            margin-top: 14px;
            background-color: #ff434f;
        }
    }

    .intro {
        margin: 28px 0 60px 0;
        font-size: 18px;
        color: #727272;
    }

    .section-bg {
        position: relative;
    }

    .section-bg::before {
        content: "";
        display: block;
        position: absolute;
        background-color: #f9fbfb;
        width: 100vw;
        height: 100%;
        z-index: -1;
    }

    /* 关于我们 */
    .about-us-section {
        padding-bottom: 32px;
    }

    /* 应用介绍布局 */
    .features {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 126px 126px;
        column-gap: 5vw;
        align-items: center;
    }

    /* 应用介绍 */
    .feature {
        display: grid;
        /* 规划栅格，图标占两行，标题和内容各占一行 */
        grid-template-areas: "icon title" "icon text";
        /* 每列的宽度，第一列60象素，第二列占满剩余空间 */
        grid-template-columns: 60px 1fr;
        /* 三行 */
        grid-template-rows: 1fr 3fr;
    }

    /* 图标 */
    .feature .fasIcon {
        grid-area: icon;
        font-size: 34px;
        color: #ff434f;
    }

    /* 标题 */
    .feature-title {
        grid-area: title;
        font-size: 18px;
        color: #494949;
    }

    /* 内容 */
    .feature-text {
        grid-area: text;
        color: #727272;
        margin-top: 8px;
    }

    .application {
        padding-top: 131px;
        box-sizing: border-box;
    }

    /* 所有服务 */
    .applications {
        display: grid;
        /* 同关于我们 */
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr 1fr;
        column-gap: 38px;
        row-gap: 34px;
    }

    /* 服务 */
    .application-item {
        /* 同关于我们 */
        display: grid;
        grid-template-areas: "icon title" "icon content";
        grid-template-columns: 70px 1fr;
        grid-template-rows: 1fr 3fr;
        /* 内边距 */
        padding: 24px;
        box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.06);
    }

    /* 服务图标 */
    .application-item .fasIcon {
        grid-area: icon;
        font-size: 42px;
        color: #ff434f;
        padding-top: 6px;
    }

    /* 服务标题 */
    .application-item .application-title {
        grid-area: title;
        color: #494949;
        font-size: 24px;
    }

    /* 服务内容 */
    .application-item .application-content {
        grid-area: content;
        color: #8b8b8b;
        line-height: 30px;
        font-size: 16px;
        /* align-self: st; */
        margin-top: 8px;
        a{
            color: #551A8B;
        }
    }

    footer {
        margin-top: 124px;
        background-color: #181818;
        display: grid;
        justify-items: center;
        padding-top: 72px;
        padding-bottom: 24px;
    }

    /* 底部菜单 */
    .footer-menus {
        /*width: 100%;*/
        max-width: 1180px;
        display: grid;
        /* 5列布局 */
        grid-template-columns: 1fr;
        padding: 0 80px;
        position: relative;
    }

    /* 导航菜单，靠右对齐 */
    .footer-menu {
        align-items: center;
        justify-self: center;
    }

    /* 一级菜单 */
    .menu-title {
        font-size: 18px;
        color: white;
        font-weight: 500;
        margin-bottom: 20px;
        text-align: center;
    }

    /* 联系我们，靠左对齐 */
    .contact-us {
        justify-self: start;
        color: #e7e9ec;
    }

    /* 联系我们，文字 */
    .contact-us p:not(:first-child) {
        padding-bottom: 16px;
    }

    /* 菜单项 */
    .menu-items li {
        list-style: none;
        padding-bottom: 8px;
    }

    /* 菜单链接 */
    .menu-items li a {
        text-decoration: none;
        font-weight: 300;
        color: #e7e9ec;
    }

    /* 备案信息 */
    .icp-info {
        margin-top: 24px;
        margin-bottom: 16px;
        height: 15px;
        display: flex;
        align-items: center;

        img {
            width: 15px;
            height: 15px;
            margin-right: 5px;
        }
    }

    /* 备案信息，版权信息 */
    .icp-info,
    .rights {
        /* 占满整行，-1代表最后一个编号 */
        /*<!--grid-column: 1 / -1;-->*/
        /* 居中对齐 */
        justify-self: center;
        color: white;
    }

    /* 返回顶部按钮，默认不显示 */
    .scrollToTop {
        display: none;
        position: relative;
        z-index: 300;
    }

    /* 返回顶部按钮 */
    .scrollToTop a {
        width: 32px;
        height: 32px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ff434f;
        color: white;
        text-decoration: none;
        position: fixed;
        bottom: 60px;
        right: 30px;
    }


    /* 自适应，小于1100象素时 */
    @media (max-width: 1100px) {
        /* 导航设置为不可见，点击折叠按钮显示全屏导航 */
        .header nav {
            display: none;
        }

        /* 头部平分两列布局 */
        header {
            grid-template-columns: repeat(2, 1fr);
        }

        /* 折叠菜单样式，显示出来 */
        header .burger {
            display: block;
            justify-self: end;
            cursor: pointer;
            position: relative;
            width: 20px;
            height: 6px;
        }

        /* 折叠按钮线条样式 */
        .burger-line1,
        .burger-line2,
        .burger-line3 {
            width: 20px;
            height: 2px;
            background-color: #e7e9ec;
            /* position: relative; */
        }

        /* 上移第一条线 */
        .burger-line1 {
            position: absolute;
            top: -6px;
        }

        /* 下移第三条线 */
        .burger-line3 {
            position: absolute;
            top: 6px;
        }

        /* 全屏导航展开时，折叠按钮设置为深色 */
        .header.open .burger-line1,
        .header.open .burger-line2,
        .header.open .burger-line3,
        .header.sticky .burger-line1,
        .header.sticky .burger-line2,
        .header.sticky .burger-line3 {
            background-color: #494949;
            transition: all 0.8s ease;
        }

        /* 全屏导航显示时，折叠按钮第一条线样式 */
        .header.open .burger-line1 {
            transform: rotate(45deg) translate(3px, 5px);
        }

        /* 全屏导航显示时，折叠按钮第二条线样式 */
        .header.open .burger-line2 {
            transform: translateX(5px);
            opacity: 0;
        }

        /* 全屏导航显示时，折叠按钮第三条线样式 */
        .header.open .burger-line3 {
            transform: rotate(-45deg) translate(3px, -5px);
        }

        /* 全屏导航显示时，导航菜单样式 */
        header.open nav {
            display: grid;
            /* 每行高度为内容的高度，不设置会平分全屏高度 */
            grid-auto-rows: max-content;
            /* 菜单项靠右对齐 */
            justify-items: start;
            position: absolute;
            top: 10px;
            left: 0;
            background: white;
            width: 100vw;
            height: 100vh;
            padding: 0 40px;
            opacity: 0;
            /* 下滑效果 */
            animation: slideDown 0.6s ease-out forwards;
        }

        .header.open .logo {
            z-index: 99;
        }

        /* 全屏导航显示时，导航菜单项样式和动画 */
        header.open nav > * {
            margin: 15px 20px;
            font-size: 18px;
            color: #494949;
            opacity: 0;
            animation: showMenu 0.5s linear forwards 0.4s;
        }

        header.open nav > a:nth-child(4) {
            &::after {
                content: '';
                display: block;
                width: 360px;
                padding-bottom: 50px;
                border-bottom: 1px solid #727272;
            }
        }

        /* 搜索按钮 */
        header.open nav > .fasIcon {
            margin-top: 10px;
            display: none;
        }

        /* 导航下滑动画 */
        @keyframes slideDown {
            from {
                height: 0;
                opacity: 0;
            }
            to {
                height: 100vh;
                padding-top: 80px;
                opacity: 1;
            }
        }

        /* 菜单项动画 */
        @keyframes showMenu {
            from {
                opacity: 0;
                transform: translateY(-1vh);
            }
            to {
                opacity: 1;
            }
        }


        /* 缩小应用介绍标题字体 */
        .application-item .application-title {
            font-size: 20px;
        }

        /* 缩小应用介绍内容字体和行距 */
        .application-item .application-content {
            font-size: 14px;
            line-height: 24px;
        }
    }

    /* 小于992象素时 */
    @media (max-width: 992px) {
        /* 轮播标题字号缩小  */
        .swiper-container h1 {
            font-size: 48px;
        }

        .swiper-container h4 {
            font-size: 18px;
        }

        .bigTitle {
            font-size: 30px;
        }

        /* 关于我们和应用介绍设置为两列布局 */
        .features,
        .applications {
            grid-template-columns: repeat(2, 1fr);
            /* 取消两行布局 */
            grid-template-rows: unset;
        }

    }

    /* 小于768象素时 */
    @media (max-width: 768px) {
        /* 区域的左右内边距设置为40象素 */
        section,
        .footer-menus {
            padding: 0 40px;
        }

        /* 关于我们和应用介绍设置为1列 */
        .features,
        .applications {
            grid-template-columns: 1fr;
        }

        /* 底部菜单设置为3列 */
        .footer-menus {
            grid-template-columns: 1fr;
            /*row-gap: 24px;*/
        }
        .menu-title {
            font-size: 16px;
        }
    }

    /* 小于576象素 */
    @media (max-width: 576px) {
        /* 缩小轮播标题文字，探索更多按钮文字 */
        .swiper-container h1 {
            font-size: 28px;
        }

        .swiper-container h4 {
            font-size: 14px;
        }

        .bigTitle {
            font-size: 24px;
        }

        .moreBtn {
            padding: 8px 18px;
            font-size: 14px;
        }


        /* 底部菜单显示为1列 */
        .footer-menus {
            grid-template-columns: 1fr;
            /* row-gap: 24px; */
        }

    }
</style>
